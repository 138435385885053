"use client";
import {
  listbox_section_default
} from "./chunk-P4E6ZB6M.mjs";
import {
  listbox_item_default
} from "./chunk-Z6HAUZMC.mjs";

// src/virtualized-listbox.tsx
import { useRef } from "react";
import { mergeProps } from "@react-aria/utils";
import { useVirtualizer } from "@tanstack/react-virtual";
import { isEmpty } from "@nextui-org/shared-utils";
import { jsx, jsxs } from "react/jsx-runtime";
var VirtualizedListbox = (props) => {
  const {
    Component,
    state,
    color,
    variant,
    itemClasses,
    getBaseProps,
    topContent,
    bottomContent,
    hideEmptyContent,
    hideSelectedIcon,
    shouldHighlightOnFocus,
    disableAnimation,
    getEmptyContentProps,
    getListProps
  } = props;
  const { virtualization } = props;
  if (!virtualization || !isEmpty(virtualization) && !virtualization.maxListboxHeight && !virtualization.itemHeight) {
    throw new Error(
      "You are using a virtualized listbox. VirtualizedListbox requires 'virtualization' props with 'maxListboxHeight' and 'itemHeight' properties. This error might have originated from autocomplete components that use VirtualizedListbox. Please provide these props to use the virtualized listbox."
    );
  }
  const { maxListboxHeight, itemHeight } = virtualization;
  const listHeight = Math.min(maxListboxHeight, itemHeight * state.collection.size);
  const parentRef = useRef(null);
  const rowVirtualizer = useVirtualizer({
    count: state.collection.size,
    getScrollElement: () => parentRef.current,
    estimateSize: () => itemHeight
  });
  const virtualItems = rowVirtualizer.getVirtualItems();
  const renderRow = ({
    index,
    style: virtualizerStyle
  }) => {
    var _a;
    const item = [...state.collection][index];
    const itemProps = {
      color,
      item,
      state,
      variant,
      disableAnimation,
      hideSelectedIcon,
      ...item.props
    };
    if (item.type === "section") {
      return /* @__PURE__ */ jsx(
        listbox_section_default,
        {
          ...itemProps,
          itemClasses,
          style: { ...virtualizerStyle, ...itemProps.style }
        },
        item.key
      );
    }
    let listboxItem = /* @__PURE__ */ jsx(
      listbox_item_default,
      {
        ...itemProps,
        classNames: mergeProps(itemClasses, (_a = item.props) == null ? void 0 : _a.classNames),
        shouldHighlightOnFocus,
        style: { ...virtualizerStyle, ...itemProps.style }
      },
      item.key
    );
    if (item.wrapper) {
      listboxItem = item.wrapper(listboxItem);
    }
    return listboxItem;
  };
  const content = /* @__PURE__ */ jsxs(Component, { ...getListProps(), children: [
    !state.collection.size && !hideEmptyContent && /* @__PURE__ */ jsx("li", { children: /* @__PURE__ */ jsx("div", { ...getEmptyContentProps() }) }),
    /* @__PURE__ */ jsx(
      "div",
      {
        ref: parentRef,
        style: {
          height: maxListboxHeight,
          overflow: "auto"
        },
        children: listHeight > 0 && itemHeight > 0 && /* @__PURE__ */ jsx(
          "div",
          {
            style: {
              height: `${rowVirtualizer.getTotalSize()}px`,
              width: "100%",
              position: "relative"
            },
            children: virtualItems.map(
              (virtualItem) => renderRow({
                index: virtualItem.index,
                style: {
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: `${virtualItem.size}px`,
                  transform: `translateY(${virtualItem.start}px)`
                }
              })
            )
          }
        )
      }
    )
  ] });
  return /* @__PURE__ */ jsxs("div", { ...getBaseProps(), children: [
    topContent,
    content,
    bottomContent
  ] });
};
var virtualized_listbox_default = VirtualizedListbox;

export {
  virtualized_listbox_default
};
