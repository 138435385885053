import React from "react";
import Header from "./header";

interface ExternalHeaderProps {
  title: string | JSX.Element;
}

const ExternalHeader: React.FC<ExternalHeaderProps> = ({ title }) => {
  return (
    <>
      <Header>The Focus Project</Header>
      <Header size="h2">{title}</Header>
    </>
  );
};

export default ExternalHeader;
