import {
  ErrorClass,
  ErrorFormat,
  GENERIC_SAFE_FMT,
  KNOWN_ERRORS,
} from "@/utils/errorFormat";
import React, { createContext, useContext, ReactNode, useMemo } from "react";

export const ErrorContext = createContext<{
  getError: (eclass: ErrorClass) => ErrorFormat;
  formatError: (error?: any) => ErrorFormat;
} | null>(null);

export const useSessionErrorContext = () => {
  const context = useContext(ErrorContext);
  if (!context) {
    throw new Error(
      "useSessionErrorContext must be used within an ErrorProvider",
    );
  }
  return context;
};

const SessionErrorContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const memoizedValue = useMemo(() => {
    const getError = (eclass: ErrorClass): ErrorFormat => {
      return KNOWN_ERRORS[eclass];
    };

    const formatError = (error?: any): ErrorFormat => {
      if (typeof error === "string") {
        const found = Object.entries(KNOWN_ERRORS).find(
          ([, ke]) => ke.internalMessage === error,
        );
        if (found) {
          return found[1];
        }
        return { ...GENERIC_SAFE_FMT, internalMessage: error };
      }
      return { ...GENERIC_SAFE_FMT, internalMessage: JSON.stringify(error) };
    };

    return { getError, formatError };
  }, []);

  return (
    <ErrorContext.Provider value={memoizedValue}>
      {children}
    </ErrorContext.Provider>
  );
};

export default SessionErrorContextProvider;
