import { LRExtensionBroker } from "@lumen-developer/lumen-common-js/esm/brokers";

import { AppDispatch } from "@/store/store";
import { ErrorFormat } from "@/utils/errorFormat";
import { ISessionStatus } from "@/components/session/sections/scoreTable";
import { Dispatch, SetStateAction } from "react";

export enum SessionState {
  START,
  POSITION,
  CALIBRATION,
  VALIDATION,
  EXTERNAL,
  RETURN,
  COMPLETE,
}

export enum SessionRoute {
  START = "/",
  LIGHTING = "/session/lighting",
  POSITION = "/session/position",
  CALIBRATION = "/session/calibration",
  VALIDATION = "/session/validation",
  EXTERNAL = "/session/external",
  RETURN = "/session/return",
  COMPLETE = "/session/complete",
}

export interface SessionSectionContext {
  broker: LRExtensionBroker;
  dispatch: AppDispatch;
  errorHandle: (error: unknown, action?: () => void) => void;
  setStatus?: Dispatch<SetStateAction<ISessionStatus>>;
  status?: ISessionStatus;
}

export interface SessionValidationContext extends SessionSectionContext {
  validationRef: React.RefObject<HTMLDivElement>;
  trackerRef: React.RefObject<HTMLDivElement>;
  validationSessionDetails: {
    panelist: string;
    panelistId: number;
    tmpSessionId: string;
    calibrationTime: number;
    accessCode: string;
  };
  setValidationStatus?: (score: number) => void;
}

export interface SessionErrorContext extends SessionSectionContext {
  errorDetail: ErrorDetail;
}
export interface SessionExternalContext extends SessionSectionContext {
  panelist: string;
  tmpSessionId: string;
  panelistId: number;
  accessCode: string;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export interface SessionCalibrationContext extends SessionSectionContext {
  calibrationRef: React.RefObject<HTMLDivElement>;
  trackerRef: React.RefObject<HTMLDivElement>;
}

export interface SessionStartContext extends SessionSectionContext {
  trackerRef: React.RefObject<HTMLDivElement>;
  panelist: string;
  panelistId: number;
  accessCode: string;
  setCanStart?: Dispatch<SetStateAction<boolean>>;
}

export interface ErrorDetail {
  fmt: ErrorFormat;
  action?: () => void;
}
