import React, { useMemo } from "react";
import {
  Table,
  TableHeader,
  TableBody,
  TableColumn,
  TableRow,
  TableCell,
  getKeyValue,
} from "@nextui-org/react";
import { IDataList } from "@/api/index";

interface RewardsTableColumn {
  key: string;
  label: string;
}

interface RewardsTableRow {
  key: number;
  event: string;
  points: string;
  date: string;
}

interface RewardsTableProps {
  data: IDataList[];
}

const RewardsTable: React.FC<RewardsTableProps> = ({ data }) => {
  const rows: RewardsTableRow[] = useMemo(() => {
    const options: Intl.DateTimeFormatOptions = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "2-digit",
    };

    return data.map(({ id, message, reward, reward_type, reward_date }) => {
      const rewardPoints = reward.toLocaleString();

      return {
        key: id,
        type: reward_type,
        event: message,
        points: reward_type === "Wild" ? `+${rewardPoints}` : rewardPoints,
        date: new Date(reward_date).toLocaleDateString(undefined, options),
      };
    });
  }, [data]);

  const columns: RewardsTableColumn[] = [
    {
      key: "type",
      label: "Type",
    },
    {
      key: "event",
      label: "Event",
    },
    {
      key: "points",
      label: "Points",
    },
    {
      key: "date",
      label: "Date",
    },
  ];

  return (
    <Table aria-label="Rewards table" radius="md" isCompact>
      <TableHeader columns={columns}>
        {(column) => (
          <TableColumn key={column.key} allowsSorting>
            {column.label}
          </TableColumn>
        )}
      </TableHeader>
      <TableBody items={rows} emptyContent="No session history to display.">
        {(item) => (
          <TableRow key={item.key}>
            {(columnKey) => (
              <TableCell>{getKeyValue(item, columnKey)}</TableCell>
            )}
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default RewardsTable;
