import React, { useState, useEffect, Dispatch, SetStateAction } from "react";

export interface PasswordStrengthBarProps {
  password: string;
  passwordScore: number;
  setPasswordScore: Dispatch<SetStateAction<number>>;
}

export const PasswordStrengthBar: React.FC<PasswordStrengthBarProps> = ({
  password,
  passwordScore,
  setPasswordScore,
}) => {
  const [width, setWidth] = useState("w-0");
  const [colour, setColour] = useState("bg-gray-200");
  const [feedback, setFeedback] = useState("too short");

  useEffect(() => {
    const calculatePasswordStrength = () => {
      if (password.length < 8) return 0;
      const hasLowercase = /[a-z]/.test(password);
      const hasUppercase = /[A-Z]/.test(password);
      const hasNumbers = /\d/.test(password);
      const hasSpecialChars = /[!@#$%^&*(),.?":{}|<>]/.test(password);
      const score = [
        hasLowercase,
        hasUppercase,
        hasNumbers,
        hasSpecialChars,
      ].filter((value) => !!value).length;

      return score;
    };

    setPasswordScore(calculatePasswordStrength);
  }, [password, setPasswordScore]);

  useEffect(() => {
    switch (passwordScore) {
      case 1:
        setWidth("w-1/4");
        setColour("bg-red-500");
        setFeedback("weak");
        break;
      case 2:
        setWidth("w-1/2");
        setColour("bg-yellow-500");
        setFeedback("ok");
        break;
      case 3:
        setWidth("w-3/4");
        setColour("bg-blue-500");
        setFeedback("good");
        break;
      case 4:
        setWidth("w-full");
        setColour("bg-green-500");
        setFeedback("strong");
        break;
      case 0:
      default:
        setWidth("w-0");
        setColour("bg-grey-200");
        setFeedback("too short");
        break;
    }
  }, [passwordScore, password]);

  return (
    <div className="mb-4">
      <div className="bg-gray-200 w-full h-0.5 mt-1">
        <div
          className={`${colour} h-0.5 transition-all duration-200 ease-in ${width}`}
        />
      </div>
      <p className="self-end text-gray-500">{feedback}</p>
    </div>
  );
};
