import React, { Dispatch, SetStateAction, useEffect } from "react";
import { useRollbar } from "@rollbar/react";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";

import { ErrorDetail } from "@/types/session";
import { LOGLEVEL } from "@/utils/errorFormat";

interface SessionErrorProps extends ErrorDetail {
  hasError: boolean;
  setHasError: Dispatch<SetStateAction<boolean>>;
}

const SessionErrorModal: React.FC<SessionErrorProps> = ({
  fmt,
  action,
  hasError,
  setHasError,
}) => {
  const rollbar = useRollbar();

  useEffect(() => {
    switch (fmt.logLevel) {
      case LOGLEVEL.ERROR:
        rollbar.error(fmt.internalMessage);
        break;
      case LOGLEVEL.WARN:
        rollbar.warn(fmt.internalMessage);
        break;
      case LOGLEVEL.INFO:
        rollbar.info(fmt.internalMessage);
        break;
      case LOGLEVEL.SILENT:
      default:
        break;
    }
  }, [fmt, rollbar]);

  return (
    <Modal
      isOpen={hasError}
      isDismissable={false}
      hideCloseButton
      isKeyboardDismissDisabled
    >
      <ModalContent>
        <ModalHeader>{action ? "Session paused" : "Session ended"}</ModalHeader>
        <ModalBody>
          <p className="">{fmt.safeMessage}</p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => {
              setHasError(false);
              if (action) {
                action();
              } else {
                window.location.reload();
              }
            }}
          >
            Retry
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SessionErrorModal;
