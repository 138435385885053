import React, { useEffect, useState } from "react";
import { PanelMessages } from "@lumen-developer/lumen-common-js/esm/panel/messages";
import { useOutletContext, useNavigate } from "react-router-dom";
import { Button } from "@nextui-org/react";
import { INIT_RESOLVE_EVENT } from "@lumen-developer/lumen-common-js/esm/brokers";

import { updateDetails } from "@/store/reducers/auth";
import { useAppSelector } from "@/hooks/store";
import { SessionRoute, SessionStartContext } from "@/types/session";
import { useAlertContext } from "@/contexts/alertContext";
import Loading from "@/components/loading/loading";
import { useSessionErrorContext } from "@/contexts/sessionErrorContext";
import {
  DetailSuccess,
  getSessionRequest,
  usePanelistDetails,
} from "../../../api";
import { setTmpSessionId } from "../../../store/reducers/session";
import { ErrorClass } from "../../../utils/errorFormat";
import extensionSendMessage from "../../../utils/extensionCom";

const SessionStart = () => {
  const {
    broker,
    dispatch,
    panelist,
    panelistId,
    accessCode,
    trackerRef,
    errorHandle,
  } = useOutletContext<SessionStartContext>();
  const navigate = useNavigate();
  const { addAlert } = useAlertContext();
  const { getError } = useSessionErrorContext();
  const [isLoading, setIsLoading] = useState(false);
  const { maxAcc, maxPrec } = useAppSelector((state) => state.auth);
  const [{ data: panelistDetails, loading, error }] = usePanelistDetails(
    accessCode,
    panelistId,
  );
  const brokerVersionConfig = useAppSelector(
    (state) => state.auth.brokerVersionConfig,
  );
  const viewabilityVersionConfig = useAppSelector(
    (state) => state.auth.viewabilityVersionConfig,
  );

  useEffect(() => {
    if (!panelistDetails) return;

    const details: DetailSuccess = {
      maxAcc: Math.round(panelistDetails.max_accuracy) || 300,
      maxPrec: Math.round(panelistDetails.max_precision) || 300,
      brokerVersionConfig: panelistDetails.broker_version_config || {},
      viewabilityVersionConfig:
        panelistDetails.viewability_version_config || {},
    };
    dispatch(updateDetails(details));
  }, [panelistDetails, dispatch]);

  useEffect(() => {
    if (error) {
      errorHandle(error);
    }
  }, [error, errorHandle]);

  const initBroker = async () => {
    const div = trackerRef.current;
    if (!div) {
      const message = getError(ErrorClass.TRACKER_DIV).safeMessage;
      addAlert({ type: "error", message, timeout: 5 });
      throw new Error(message);
    }
    await broker.init(30000, div, {
      resolveEvent: INIT_RESOLVE_EVENT.GAZE_DETECTOR_INIT,
    });
  };

  const cont = async () => {
    setIsLoading(true);
    try {
      const m: PanelMessages.ExternalMessage = {
        type: PanelMessages.ExternalMessageType.CheckPermissions,
      };
      const perms: boolean = await extensionSendMessage(m);
      if (!perms) {
        const message = getError(ErrorClass.CAMERA_PERMISSIONS).safeMessage;
        addAlert({ type: "error", message, timeout: 5 });
      }
      const res = await getSessionRequest(
        panelist,
        maxAcc,
        maxPrec,
        brokerVersionConfig,
        viewabilityVersionConfig,
      );
      dispatch(setTmpSessionId(res.tmpSessionId));
      // TODO: HANDLE GET SESSION ID ERROR
      if (!broker.state.initialised) {
        await initBroker();
      }
      navigate(SessionRoute.LIGHTING);
    } catch (e) {
      if (e === ErrorClass.EXTENSION_COMMS_FAIL) {
        window.location.href = "/install";
      } else {
        errorHandle(e);
      }
    }
    setIsLoading(false);
  };

  if (loading || isLoading) return <Loading showLabel={false} />;

  return (
    <>
      <div className="mb-10">
        <Button color="secondary" onClick={cont}>
          Start a 10 minute Research session
        </Button>
      </div>
      <p>Note: We do not record pictures or video of you</p>
    </>
  );
};

export default SessionStart;
