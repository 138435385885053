import React, { useState } from "react";

import EmailConfirmation from "@/components/signUp/emailConfirmation";
import SignUpForm from "@/components/signUp/signUpForm";

const SignUp = () => {
  const [email, setEmail] = useState("");

  if (email) return <EmailConfirmation setEmail={setEmail} email={email} />;

  return <SignUpForm setEmail={setEmail} />;
};

export default SignUp;
