import React, { useState } from "react";
import { PanelMessages } from "@lumen-developer/lumen-common-js/esm/panel/messages";
import { useOutletContext } from "react-router-dom";
import { Button } from "@nextui-org/react";

import { SessionExternalContext } from "@/types/session";
import { useSessionErrorContext } from "@/contexts/sessionErrorContext";
import { useAlertContext } from "@/contexts/alertContext";
import Header from "@/components/header/header";
import { startWildRequest } from "../../../api";
import { ErrorClass } from "../../../utils/errorFormat";
import extensionSendMessage from "../../../utils/extensionCom";

const SessionExternal = () => {
  const {
    broker,
    panelist,
    tmpSessionId,
    panelistId,
    accessCode,
    errorHandle,
    setIsLoading,
  } = useOutletContext<SessionExternalContext>();
  const { addAlert } = useAlertContext();
  const [canClick, setCanClick] = useState(true);
  const { getError } = useSessionErrorContext();

  const start = async () => {
    setIsLoading(true);
    setCanClick(false);
    try {
      broker.saveModel("model", "errorCorrection");
      if (broker.trackersController) {
        await broker.turnOffCamera();
      }
      const model = localStorage.getItem("model");
      const errorCorrection = localStorage.getItem("errorCorrection");

      if (model && errorCorrection) {
        console.log("sending model to extension...");
        const m: PanelMessages.InitSession = {
          type: PanelMessages.ExternalMessageType.InitSession,
          data: {
            broker: {
              model,
              errorCorrection,
            },
            panelist,
            panelistId,
            tmpSessionId,
            sessionLength: 600000, // TODO: SHOULD BE FROM STATE,
          },
        };
        const canStart = await extensionSendMessage(m);
        if (canStart) {
          await startWildRequest(accessCode, tmpSessionId, panelistId);
          // TODO: BETTER HANDLE SESSION START REDIRECT
          window.location.href = "https://google.com/";
        } else {
          const message = getError(ErrorClass.EXT_INIT_FAIL).safeMessage;
          addAlert({ type: "error", message, timeout: 5 });
          throw new Error(message);
        }
      } else {
        const message = getError(ErrorClass.MISSING_MODEL).safeMessage;
        addAlert({ type: "error", message, timeout: 5 });
        throw new Error(message);
      }
    } catch (e) {
      errorHandle(e);
    }
    setIsLoading(false);
  };

  return (
    <>
      <Header size="h2"> How to earn points in your session?</Header>
      <ol className="mb-8 list-decimal list-inside">
        <li>You will be redirected to a random website</li>
        <li>
          For the duration of the session browse the internet as you usually
          would
        </li>
        <li>
          After 10 minutes you will be automatically redirected in a new tab to
          a validation screen
        </li>
        <li>Complete a successful validation in order to secure your points</li>
      </ol>
      {/* TODO: BETTER DATE FORMATTING */}
      <p className="mb-8">Your session will last 10 minutes.</p>
      <Button color="secondary" disabled={!canClick} onClick={start}>
        Start Session
      </Button>
    </>
  );
};

export default SessionExternal;
