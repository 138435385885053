/* eslint-disable react/no-unescaped-entities */
import React from "react";

import { Accordion, AccordionItem } from "@nextui-org/react";
import { useAppSelector } from "@/hooks/store";
import Loading from "@/components/loading/loading";
import { useTerms } from "../api";
import ErrorMessage from "./errorMessage";

const TermsAndPrivacy = () => {
  const { panelistId, accessCode, loggedIn } = useAppSelector(
    (state) => state.auth,
  );
  const [{ data, loading }] = useTerms(accessCode, panelistId, loggedIn);

  if (loading) return <Loading />;
  if (!data?.success) return <ErrorMessage />;

  return (
    <>
      <h1 className="font-semibold mb-10 text-2xl">
        Focus Project Research - Terms and Privacy
      </h1>
      <Accordion variant="bordered">
        {data?.doc_list?.map(({ doc_name, doc_name_formatted, text }) => (
          <AccordionItem
            title={doc_name_formatted}
            key={doc_name}
            aria-label={doc_name}
          >
            <div className="whitespace-pre-wrap">{text}</div>
          </AccordionItem>
        ))}
      </Accordion>
    </>
  );
};

export default TermsAndPrivacy;
