import React, { ReactNode } from "react";

interface HeaderProps {
  children: ReactNode;
  size?: "h1" | "h2";
}

const Header: React.FC<HeaderProps> = ({ size = "h1", children }) => {
  switch (size) {
    case "h2":
      return <h2 className="text-2xl mb-4 flex gap-2">{children}</h2>;
    case "h1":
    default:
      return <h1 className="text-3xl mb-5">{children}</h1>;
  }
};

export default Header;
