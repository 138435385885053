import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Root from "./root";
import Install from "./pages/install";
import SessionStart from "./components/session/sections/start";
import SessionPosition from "./components/session/sections/position";
import SessionCalibration from "./components/session/sections/calibration";
import SessionValidation from "./components/session/sections/validation";
import SessionExternal from "./components/session/sections/external";
import SessionReturn from "./components/session/sections/return";
import SessionCompletion from "./components/session/sections/complete";
import InstallMobileApp from "./components/install/installMobileApp";
import FAQs from "./pages/faqs";
import Rewards from "./pages/rewards";
import SignUp from "./pages/signUp";
import Tasks from "./pages/tasks";
import ForgotPassword from "./pages/forgotPassword";
import SessionLighting from "./components/session/sections/lighting";
import DataDeletion from "./pages/dataDeletion";
import Session from "./components/session/session";
import { SessionRoute } from "./types/session";
import ErrorMessage from "./pages/errorMessage";
import TermsAndPrivacy from "./pages/termsAndPrivacy";
import PasswordForm from "./components/password/passwordForm";
import SessionErrorContextProvider from "./contexts/sessionErrorContext";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorMessage />,
    children: [
      {
        path: "/",
        element: (
          <SessionErrorContextProvider>
            <Session />
          </SessionErrorContextProvider>
        ),
        children: [
          {
            path: SessionRoute.START,
            element: <SessionStart />,
          },
          {
            path: SessionRoute.LIGHTING,
            element: <SessionLighting />,
          },
          {
            path: SessionRoute.POSITION,
            element: <SessionPosition />,
          },
          {
            path: SessionRoute.CALIBRATION,
            element: <SessionCalibration />,
          },
          {
            path: SessionRoute.VALIDATION,
            element: <SessionValidation />,
          },
          {
            path: SessionRoute.EXTERNAL,
            element: <SessionExternal />,
          },
          {
            path: SessionRoute.RETURN,
            element: <SessionReturn />,
          },
          {
            path: SessionRoute.COMPLETE,
            element: <SessionCompletion />,
          },
        ],
      },
      {
        path: "/install",
        element: <Install />,
      },
      {
        path: "/terms-and-privacy",
        element: <TermsAndPrivacy />,
      },
      {
        path: "/frequently-asked-questions",
        element: <FAQs />,
      },
      {
        path: "/rewards",
        element: <Rewards />,
      },
      {
        path: "/account-admin/sign-up",
        element: <SignUp />,
      },
      {
        path: "/tasks",
        element: <Tasks />,
      },
      {
        path: "/account-admin/forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "/data-deletion-request",
        element: <DataDeletion />,
      },
      {
        path: "/mobile-app",
        element: <InstallMobileApp />,
      },
      {
        path: "/account-admin/reset-password",
        element: <PasswordForm title="Reset Password" />,
      },
      {
        path: "/account-admin/create-password",
        element: <PasswordForm title="Create Password" />,
      },
      {
        path: "*",
        element: <ErrorMessage message="This page could not be found" />,
      },
    ],
  },
]);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
