import {
  tv
} from "./chunk-UWE6H66T.mjs";

// src/components/form.ts
var form = tv({
  base: "flex flex-col gap-2 items-start"
});

export {
  form
};
