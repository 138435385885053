import { Spinner } from "@nextui-org/react";
import React from "react";

interface LoadingProps {
  showLabel?: boolean;
  absolutePositioning?: boolean;
  size?: "sm" | "md" | "lg";
}

const Loading: React.FC<LoadingProps> = ({
  showLabel = true,
  absolutePositioning = true,
  size = "lg",
}) => {
  return (
    <Spinner
      size={size}
      className={
        absolutePositioning
          ? "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
          : null
      }
      label={showLabel ? "Loading..." : null}
      labelColor="primary"
    />
  );
};

export default Loading;
