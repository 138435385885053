import { Dispatch, SetStateAction } from "react";

export const disabledTimer = (
  setIsDisabled: Dispatch<SetStateAction<boolean>>,
  timer = 1000,
) => {
  setIsDisabled(true);
  setTimeout(() => {
    setIsDisabled(false);
  }, timer);
};

export const dummy = null;
